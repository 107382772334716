<script lang="ts">
	import Button from '$lib/components/Button.svelte';
	import Modal from '$lib/components/Modal.svelte';
	import Login from '$lib/components/auth/Login.svelte';
	import Register from '$lib/components/auth/Register.svelte';
	import { browser } from '$app/environment';
	import { clickOutside } from '$lib/utils/clickOutside';
	import Nav from '$lib/components/header/Nav.svelte';
	import { openModal } from 'svelte-modals';
	import { Menu } from 'lucide-svelte';
	import { afterNavigate, beforeNavigate, goto } from '$app/navigation';
	import Icons from '$lib/components/Icons.svelte';
	import { authenticated, user } from '$lib/stores/user';
	import posthog from 'posthog-js';
	import UserDropdown from './UserDropdown.svelte';

	let classNames = '';
	export { classNames as class };

	export let showNav = false;
	export let showLogin = true;

	function handleOpenModal() {
		openModal(Modal, { component: Login, props: {} });
	}

	function handleOpenRegisterModal() {
		openModal(Modal, { component: Register, props: {} });
	}

	beforeNavigate(() => {
		showNav = false;
	});

	afterNavigate(() => {
		const contentDiv = document.querySelector('.content-main');
		contentDiv?.scrollTo({
			top: 0
		});
	});

	const onShowNav = () => {
		showNav = !showNav;
	};

	$: switch ($authenticated) {
		case true:
			posthog.identify($user?.id.toString(), {
				$distinct_id: $user?.id,
				$username: $user?.username,
			});
			break;
	}

</script>

<header class="{`${classNames || 'border-b-black'}`} fixed top-0 flex justify-between w-full px-3">
	<div class="nav-button">
		<button aria-label="Mobil Navigerings meny" on:click={onShowNav}><Menu /></button>
	</div>
	<div class="py-1 logo-container">
		<a href="/">
			<enhanced:img
				alt="PlayPark Logo"
				src="$lib/assets/logotype.png?width=240&height=60"
				class="header-logo logo-large"
				loading="lazy"
			/>
			<enhanced:img
				alt="PlayPark Logo"
				src="$lib/assets/logo.png?w=40"
				class="header-logo logo-small"
				loading="lazy"
			/>
		</a>

		<div class="nav-horizontal {showNav ? 'show-nav' : ''}">
			<Nav />
		</div>
	</div>
	{#if showLogin}
		<div class="flex items-center user">
			{#if browser}
				{#if $authenticated}
					<UserDropdown />
				{:else}
					<Button class="button-smaller" on:click={handleOpenModal}>Logga in</Button>
					<Button class="button-smaller" on:click={handleOpenRegisterModal}>Registrera</Button>
				{/if}
			{/if}
		</div>
	{/if}
</header>

<style lang="scss">
	header {
		height: 52px;
		max-width: 100vw;

		.user {
			margin-right: 16px;
		}

		.nav-button {
			display: none;
		}

		@media screen and (max-width: 1050px) {
			.nav-horizontal {
				display: none;

				&.show-nav {
					display: block;
					position: absolute;
					top: 52px;
					left: 0;
					height: calc(100vh - 52px);
					width: 100%;
					background: var(--background-darker);
					box-shadow: 0 8px 7px -5px #00000075;
					z-index: 1;
				}
			}

			.nav-button {
				display: flex;
			}

			.user {
				margin-right: 0px;
			}
		}
	}

	.header-game-portal {
		background: var(--background-dark);
		box-shadow: 0 8px 7px -5px #00000075;
		z-index: 1;
	}

	.header-front-page {
		z-index: 1;
	}

	.header-steam-login {
		width: 32px;
		height: 32px;
		margin-right: 32px;
		margin-left: 16px;
	}

	.header-login-modal {
		z-index: 2;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.header-logo {
		max-width: 200px;
		height: fit-content;
	}

	.username-title {
		font-weight: 500;
		letter-spacing: 0.5px;
	}

	.username-description {
		font-size: x-small;
		color: #8b8b8b;
	}

	.dropdown-menu {
		width: 10rem;
		margin-top: 8px;
		margin-right: 16px;
		background-color: #131315;
		padding: 6px;
		position: absolute;
		right: 0px;
		border: 1px solid #27272a;
		border-radius: 10px;
		z-index: 1;
		opacity: 1;
		visibility: visible;
	}

	.divider {
		border: 0;
		height: 1px;
		background-color: #27272a;
		margin: 8px 0;
	}

	.logo-small {
		display: none;
	}

	.logo-large {
		display: block;
	}

	.logo-container {
		display: flex;
		align-items: center;
	}

	@media screen and (max-width: 500px) {
		.logo-small {
			display: block;
		}

		.logo-large {
			display: none;
		}
		.logo-container {
			display: block;
			margin-left: 20px;
			width: 100%;
		}
	}
</style>
