<script lang="ts">
	import { page } from '$app/stores';
</script>

<nav class="flex items-center">
	<ul>
		<li aria-current={$page.url.pathname === '/forum' ? 'page' : undefined}>
			<a href="/forum">Forum</a>
		</li>
		<li aria-current={$page.url.pathname === '/tournaments' ? 'page' : undefined}>
			<!-- <a href="/tournaments">Turneringar</a> -->
			<span>Turneringar</span>
		</li>
		<li aria-current={$page.url.pathname.startsWith('/servers') ? 'page' : undefined}>
			<a href="/servers">Servrar</a>
		</li>
		<li aria-current={$page.url.pathname === '/scrim' ? 'page' : undefined}>
			<!-- <a href="/scrim">Scrim</a> -->
			<span>Scrim</span>
		</li>
	</ul>
</nav>

<style lang="scss">
	nav {
		display: flex;
		justify-content: center;
		margin-left: 32px;
	}

	ul {
		position: relative;
		padding: 0;
		margin: 0;
		height: 3em;
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		background: var(--background);
		background-size: contain;
	}

	li {
		position: relative;
		height: 100%;
	}

	nav a {
		display: flex;
		height: 100%;
		align-items: center;
		padding: 0 0.5rem;
		color: var(--color-text);
		font-weight: 700;
		font-size: 0.8rem;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		text-decoration: none;
		transition: color 0.2s linear;
	}

	nav span {
		display: flex;
		height: 100%;
		align-items: center;
		padding: 0 0.5rem;
		color: rgb(75, 75, 75);
		font-weight: 700;
		font-size: 0.8rem;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		text-decoration: none;
		transition: color 0.2s linear;
		cursor: not-allowed;
	}

	a:hover {
		color: var(--color-theme-1);
	}

	@media (max-width: 768px) {
		nav {
			margin-left: 0;
			display: flex;
			justify-content: flex-start;

			ul {
				position: relative;
				padding: 0;
				margin: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: center;
				list-style: none;
				background: var(--background);
				background-size: contain;
				height: unset;
				width: 100%;

				li {
					width: 100%;
					background: #0a0a0c;
					cursor: pointer;

					a {
						padding: 16px;
					}

					span {
						padding: 16px;
					}

					&:hover {
						background: #131418;
					}
				}
			}
		}
	}
</style>
